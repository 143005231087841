<template>
  <div class="App-Footer-Banner">
    <div class="App-Footer-Banner-H">
      <div>
        <div class="App-Footer-Banner-H-M">
          <h1 f-w-700 m-tb-0>
            Let us help you<br />
            find entrepreneurial talent that will create boost for your startup
            team.
          </h1>
          <p>
            Share an entrepreneurial opportunity, and get rapid access to talent
            who will boost your team for the challenge at hand.
          </p>
          <el-button
            icon="el-icon-right"
            v-get-started
            size="large"
            type="primary"
          >
            Get Started
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {}
</script>

<style lang="scss">
.App-Footer-Banner {
  padding: 20px 10px;
  margin-bottom: 20px;
  .App-Footer-Banner-H {
    padding: 20px 20px;
    min-height: 350px;
    text-align: center;
    background: rgba($color: $--color-primary, $alpha: 0.05);
    border-radius: 6px;
    max-width: 860px;
    display: block;
    width: 100%;
    margin: auto;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .App-Footer-Banner-H-M {
    max-width: 500px;
    margin: auto;
  }

  h1 {
    font-weight: 700;
  }
  p {
    font-size: 130%;
  }
}
</style>
