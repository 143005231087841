
import { Options, Vue } from "vue-class-component";
import { CircleCheck } from "@element-plus/icons";

@Options({
  components: { CircleCheck },
})
export default class extends Vue {
  links = [
    {
      label: "Terms of Service",
      url: "/terms/terms-of-service",
    },
    {
      label: "Terms of Use",
      url: "/terms/terms-of-use",
    },
    {
      label: "Privacy Policy",
      url: "/terms/privacy-policy",
    },
    {
      label: "Cookie Policy",
      url: "/terms/cookie-policy",
    },
  ];

  otherLinks = [
    {
      label: "Glossary",
      url: "/terms/glossary",
    },
  ];
}
