import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppFounderTalentPage from "../views/founder-talent-page/founder-talent-page.vue";
import AppWhyE4TPage from "../views/why-e4t-page/why-e4t-page.vue";
import AppAboutPage from "../views/about-page/about-page.vue";
import AppHomePage from "../views/home-page/home-page.vue";
import AppTermsPage from "../views/terms-page/terms-page.vue";
import AppContactPage from "../views/contact-page/contact-page.vue";
/**
 *
 */
import AppTermsOfUse from "../views/terms-page/subpages/terms-of-use.vue";
import AppTermsOfService from "../views/terms-page/subpages/terms-of-service.vue";
import AppPrivacyPolicy from "../views/terms-page/subpages/privacy-policy.vue";
import AppGlossary from "../views/terms-page/subpages/glossary.vue";
import AppCookiePolicy from "../views/terms-page/subpages/cookie-policy.vue";
/**
 *
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppHomePage,
  },
  {
    path: "/talents",
    redirect: "/founder-talent",
  },
  {
    path: "/talent", // This stays
    redirect: "/founder-talent",
  },
  {
    path: "/about",
    component: AppAboutPage,
  },
  {
    path: "/why-e4t",
    component: AppWhyE4TPage,
  },
  {
    path: "/founder-talent",
    component: AppFounderTalentPage,
  },
  {
    path: "/FAQ",
    component: AppHomePage,
  },
  {
    path: "/contact",
    component: AppContactPage,
  },
  {
    path: "/terms",
    component: AppTermsPage,
    children: [
      {
        path: "terms-of-use",
        component: AppTermsOfUse,
        meta: { title: "Terms of use" },
      },
      {
        path: "terms-of-service",
        component: AppTermsOfService,
        meta: { title: "Terms of service" },
      },
      {
        path: "privacy-policy",
        component: AppPrivacyPolicy,
        meta: { title: "Privacy policy" },
      },
      {
        path: "cookie-policy",
        component: AppCookiePolicy,
        meta: { title: "Cookie policy" },
      },
      {
        path: "glossary",
        component: AppGlossary,
        meta: { title: "Glossary" },
      },
      {
        path: ":pathMatch(.*)*",
        redirect: "/terms/terms-of-use",
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
