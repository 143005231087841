import { apiPlugin, ApiPluginOptions, TitleMixin } from "@/shared";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/display.css";
import { createApp } from "vue";
import VueGtag from "vue-gtag";
import AppFooter from "../shared/components/footer.component.vue";
//
import AppLogo from "../shared/components/logo.vue";
import App from "./App.vue";
import AppBlockQuote from "./components/blockquote.vue";
import AppMainFooter from "./components/footer/footer.vue";
import AppHowItworks from "./components/how-it-works.vue";
import AppMainLayout from "./components/layout/main-layout.vue";
//
import AppTopNav from "./components/navigation/top-nav.vue";
import { getStartedDirective } from "./directives/get-started";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import CookieNotice from "@/shared/components/cookie-notice/cookie-notice-component.vue";


/**
 * Create the main app and attach the needed plugins and modules
 */
const app = createApp(App);
/**
 * Register global components
 */
app
  .use(store)
  .use(router)
  .use(apiPlugin, {
    apis: {
      main: process.env.VUE_APP_API_MAIN,
      admin: process.env.VUE_APP_API_ADMIN,
    },
  } as ApiPluginOptions)
  .use(ElementPlus)
  // Enable google analytics
  .use(VueGtag, {
    appName: "About",
    config: { id: "G-EHPYD80XK1" },
  })
  .mixin(TitleMixin)
  //
  // Import components
  //
  .component("app-top-nav", AppTopNav)
  .component("app-main-footer", AppMainFooter)
  .component("app-main-layout", AppMainLayout)
  .component("app-how-it-works", AppHowItworks)
  //
  .component("app-logo", AppLogo)
  .component("app-footer", AppFooter)
  //
  .component("app-cookie-notice", CookieNotice)
  //
  .component("app-blockquote", AppBlockQuote)
  //
  //
  .directive("get-started", getStartedDirective)
  //
  //
  .mount("#app");
