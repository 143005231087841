
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/about/founder.svg";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;
}
