
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
  data() {
    return {
      content: undefined,
    };
  },
})
export default class extends Vue {
  @Prop({})
  path!: string;

  loading = true;

  /**
   * Mounted
   */
  mounted() {
    this.fetch();
  }

  /**
   * @fetch
   */
  fetch() {
    this.$api.main
      .get(`/v1/terms/${this.path}`)
      .then((resp) => {
        (this.$data as any).content = resp.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
