<template>
  <app-main-layout class="App-Page-H">
    <!--  -->
    <template v-slot:top-nav>
      <app-top-nav />
    </template>
    <!--  -->
    <template v-slot:main>
      <div>
        <div>
          <AppContactForm />
        </div>
      </div>
    </template>
    <!--  -->
    <template v-slot:footer>
      <app-main-footer :showBanner="false" />
    </template>
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AppContactForm from "./contact-form.vue";

@Options({
  components: { AppContactForm },
})
export default class extends Vue {}
</script>

<style lang="scss"></style>
