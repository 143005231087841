
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FooterBanner from "./footer-banner.vue";

@Options({
  components: { FooterBanner },
})
export default class extends Vue {
  @Prop({ default: true })
  showBanner!: boolean;
  links = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Founder & Talent",
      url: "/founder-talent",
    },
    {
      label: "Why E4T",
      url: "/why-e4t",
    },
    {
      label: "About Us",
      url: "/about",
    },
    {
      label: "Contact Us",
      url: "/contact",
    },
    // {
    //   label: "FAQ",
    //   url: "/faq",
    // },
  ];
}
