<template>
  <div class="App-Main-Lay">
    <!--  -->
    <!-- Back -->
    <div
      class="App-Main-Lay-Back"
      :style="{ backgroundImage: `url('${img}')` }"
    ></div>
    <!--  -->
    <!--  -->
    <div class="App-Main-Lay-H">
      <el-container>
        <!--  -->
        <el-header class="App-Main-Lay-Nav">
          <slot name="top-nav" />
        </el-header>
        <!--  -->
        <el-main
          style="padding: 0"
          :class="putOnMax ? 'Max' : ''"
          class="App-Main-Lay-Main"
        >
          <slot name="main" />
        </el-main>
        <!--  -->
        <el-footer class="App-Main-Lay-Footer">
          <slot name="footer" />
        </el-footer>
        <!--  -->
      </el-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/about/background.svg";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  img = img;

  @Prop({ default: true })
  putOnMax!: boolean;
}
</script>

<style lang="scss">
.App-Main-Lay {
  // background: ;
  background-position: top;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  // Holder
  .App-Main-Lay-H {
    // Max width
    max-width: 1200px;
    margin: auto;
  }
  // Main center content
  .App-Main-Lay-Main {
    min-height: calc(100vh - 64px - 700px);
    background: rgba(255, 255, 255, 0.707);
    margin: auto;
    width: 100%;
    max-width: 100vw;
    &.Max {
      max-width: 860px;
    }
  }
  //
  // background
  .App-Main-Lay-Back {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: -20px;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.7;
    background-attachment: fixed;
  }

  //
  .App-Main-Lay-Nav {
    // max-width: 860px;
    background: white;
    margin: auto;
    width: 100%;
    padding: 0;
    line-height: 70px;

    .el-affix {
      width: 100% !important;
    }
    .App-M-Top-Nav {
      padding: 0 10px;
    }
  }
}
//
// Responsiveness

// Large devices (desktops, 992px and up)
@media (max-width: 768px) {
  .App-Main-Layout {
    max-width: 990px;
  }
  .App-Main-Lay-Main {
    padding: 10px 20px !important;
  }
  .column {
    // width: 100% !important ;
  }
}
</style>
