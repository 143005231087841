import { Directive } from "@vue/runtime-core";

export const getStartedDirective: Directive = {
  /**
   *
   * @param el
   * @param binding
   * @param vnode
   */
  mounted(el: HTMLElement, binding, vnode) {
    el.addEventListener("click", (ev) => {
      console.log("Was clicked #21",process.env.VUE_APP_BASE_URL_AUTH);
      window.open(
        `${process.env.VUE_APP_BASE_URL_AUTH}/signup/founder`
      );
    });
  },
};
