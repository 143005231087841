<template>
  <app-main-layout class="App-Page-H">
    <!--  -->
    <template v-slot:top-nav>
      <app-top-nav />
    </template>
    <!--  -->
    <template v-slot:main>
      <div>
        <AboutTop />
        <div>
          <!-- <app-how-it-works /> -->
        </div>
      </div>
    </template>
    <!--  -->
    <template v-slot:footer>
      <app-main-footer />
    </template>
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AboutTop from "./about-top.vue";

@Options({
  components: {
    AboutTop,
  },
})
export default class extends Vue {}
</script>

<style lang="scss"></style>
