<template>
  <div class="App-HowItW">
    <div class="App-HowItW-H">
      <div>
        <!--  -->
        <div class="App-HowItW-Header" txt-center>
          <h1 txt-center primary>How it works</h1>
          <p txt-center>Finding your fit... how to get started</p>
          <br />
        </div>
        <!--  -->
        <el-row justify="space-around">
          <el-col :span="24" :lg="11">
            <h3 f-w-700 style="margin-left: 20px">For Founders</h3>
            <div class="App-HowItW-Cont">
              <div
                class="App-HowItW-Item"
                v-for="step of founderSteps"
                :key="step.description.slice(0, 16)"
              >
                <div class="App-HowItW-Icon">
                  <el-icon><circle-check /></el-icon>
                </div>
                <div class="App-HowItW-Desc">{{ step.description }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="24" :lg="11">
            <h3 f-w-700 style="margin-left: 20px">For Talents</h3>
            <div class="App-HowItW-Cont">
              <div
                class="App-HowItW-Item"
                v-for="step of talentSteps"
                :key="step.description.slice(0, 16)"
              >
                <div class="App-HowItW-Icon">
                  <el-icon><circle-check /></el-icon>
                </div>
                <div class="App-HowItW-Desc">{{ step.description }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { CircleCheck } from "@element-plus/icons";

@Options({
  components: { CircleCheck },
})
export default class extends Vue {
  founderSteps = [
    { description: "Go to the E4T marketplace" },
    { description: "Register and complete the talent profile you need" },
    {
      description:
        "See talent candidates tailored to your specific opportunity",
    },
    { description: "Find talent candidates with Boost" },
  ];

  talentSteps = [
    {
      description:
        "Register upon being invited and complete a talent assessment",
    },
    {
      description:
        "See entrepreneurial opportunities that match your skills, purpose and desired rewards",
    },
    {
      description:
        "Find your Boost founding team and get early access to opportunities",
    },
  ];
}
</script>

<style lang="scss">
.App-HowItW {
  min-height: 300px;
  padding: 20px 4px;
  .App-HowItW-H {
    border-radius: 6px;
    background: rgba($color: $--color-primary, $alpha: 0.05);
    padding: 40px 10px;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // Header
  .App-HowItW-Header {
    h1 {
      font-size: 2rem;
      margin: 0 10px;
    }
    p {
      margin: 0 auto;
      font-size: 140%;
      max-width: 400px;
    }
  }
  // Content
  .App-HowItW-Cont {
    margin-top: 20px;
    padding: 4px 10px;
    max-width: 500px;
    margin: auto;
  }
  .App-HowItW-Item {
    padding: 10px 4px;
    display: flex;
    align-items: flex-start;
    .App-HowItW-Icon {
      padding: 4px;
      display: flex;
      align-items: center;
      .el-icon {
        color: $--color-primary;
        font-size: 34px;
        font-weight: bolder;
      }
    }
    .App-HowItW-Desc {
      font-size: 110%;
      padding: 8px 4px;
      max-width: 450px;
    }
  }
}
</style>
