<template>
  <div>
    <app-loader-empty :loading="loading" :resources="resources">
      <!--  -->
      <template #loading>
        <div v-if="loading">
          <el-skeleton style="width: 100%" :loading="loading" animated>
            <template #template>
              <div style="padding: 8px">
                <!-- Top Part -->
                <!--  -->
                <!-- Middle Part -->
                <el-space :size="10">
                  <el-skeleton-item
                    variant="circle"
                    style="width: 50px; height: 50px"
                  />
                  <el-skeleton-item variant="h1" style="width: 350px" />
                </el-space>
                <!--  -->
                <!--  -->
                <!--  -->
                <div style="padding: 14px 2px">
                  <el-space w-100 :size="10">
                    <el-skeleton-item variant="text" style="width: 300px" />
                    <el-skeleton-item variant="text" style="width: 50px" />
                  </el-space>
                  <el-skeleton-item variant="text" style="width: 90%" />
                  <el-skeleton-item variant="text" style="width: 80%" />
                  <el-skeleton-item variant="text" style="width: 60%" />
                </div>
                <!--  -->
                <!--  -->
                <el-row>
                  <el-col :span="24 / 3"
                    ><el-skeleton-item variant="h1" style="width: 50px"
                  /></el-col>
                  <el-col style="text-align: right" :span="24 / 3"
                    ><el-skeleton-item variant="h1" style="width: 50px"
                  /></el-col>
                  <el-col style="text-align: right" :span="24 / 3"
                    ><el-skeleton-item variant="h1" style="width: 50px"
                  /></el-col>
                </el-row>
                <!--  -->
                <!--  -->
              </div>
            </template>
          </el-skeleton>
        </div>
      </template>
      <!--  -->
      <template #empty>
        <el-empty
          v-if="resources === undefined || resources.length === 0"
          :image="img"
        >
          <template #description>
            <h2 m-tb-0>Oops!</h2>
            <p v-html="description"></p>
          </template>
          <!-- v-if  || isFounder -->
          <el-button
            v-if="false"
            is-borderless
            plain
            type="primary"
            icon="el-icon-plus"
            size="large"
            @click="clickedEmpty"
          >
            Source Talents
          </el-button>
          <!--  -->
        </el-empty>
      </template>
      <!--  -->
    </app-loader-empty>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import img from "@/assets/illustrations/talent/no-talents.svg";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;

  @Prop({ type: String, default: "No Talents Were Found" })
  description!: string;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ type: Object })
  resources!: any[];

  get isFounder() {
    return this.$store.state.account.user?.type === "founder";
  }

  @Emit("clickedEmpty")
  clickedEmpty() {
    this.$store.commit("modals/opportunity", true);
  }
}
</script>

<style lang="scss"></style>
