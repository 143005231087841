<template>
  <app-main-layout :putOnMax="false">
    <!--  -->
    <template v-slot:top-nav>
      <app-top-nav />
    </template>
    <!--  -->
    <template v-slot:main>
      <div>
        <div class="App-Terms-Page-Header">
          <h1 primary f-w-700>{{ $route.meta.title }}</h1>
        </div>
        <el-container class="App-Terms-Subpages-H">
          <div target="body" :offset="70">
            <el-aside width="200px">
              <TermsNavComponent />
            </el-aside>
          </div>
          <el-main class="App-Terms-Sub-H-Main">
            <router-view v-slot="{ Component }">
              <el-collapse-transition>
                <component :is="Component" />
              </el-collapse-transition>
            </router-view>
          </el-main>
        </el-container>
      </div>
    </template>
    <!--  -->
    <template v-slot:footer>
      <app-main-footer :showBanner="false" />
    </template>
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TermsNavComponent from "./terms-nav.vue";
import { ElCollapseTransition } from "element-plus";

@Options({
  components: {
    TermsNavComponent,
    [ElCollapseTransition.name]: ElCollapseTransition,
  },
})
export default class extends Vue {}
</script>

<style lang="scss">
.App-Terms-Page-Header {
  max-width: 600px;
  text-align: center;
  padding: 20px 10px;
  margin: 1rem auto;

  h1 {
    margin: 0.5rem 0;
    font-size: 2rem;
  }
  p {
    margin: 0;
    font-size: 130%;
  }
}
//
// Sub pages holder
.App-Terms-Subpages-H {
  min-height: 600px;
  border-radius: 8px;
  background-color: rgba(121, 185, 187, 0.12);
}

// Subpages styles
.App-Terms-Sub-Header {
  margin: 4px auto 20px auto;
  max-width: 600px;
  text-align: center;
  h1 {
    font-size: 1.4rem;
    margin: 2px auto;
  }
  h2 {
    margin: 6px auto;
    font-weight: 600;
  }
  p {
    font-size: 120%;
    margin: 2px auto;
  }
}
.App-Terms-Sub-H-Main {
  background: rgba($color: white, $alpha: 0.6);
  border-radius: 6px;
  padding-bottom: 50px;
}
</style>
