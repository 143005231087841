
import { glossary } from "@/shared";
import { Options, Vue } from "vue-class-component";

@Options({
  title: "E4T: Glossary",
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  items = glossary;
}
