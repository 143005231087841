
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TopNavSmall from "./top-nav-small.vue";

@Options({
  components: {
    TopNavSmall,
  },
})
export default class extends Vue {
  @Prop({ type: Boolean, default: true })
  showGetStarted!: boolean;

  authLink = `${process.env.VUE_APP_BASE_URL_AUTH}/login`;

  navigateToSignIn() {
    window.open(this.authLink);
  }
  links = [
    {
      label: "Founder & Talent",
      url: "/founder-talent",
    },
    {
      label: "Why E4T",
      url: "/why-e4t",
    },
    {
      label: "About Us",
      url: "/about",
    },
    // {
    //   label: "FAQ",
    //   url: "/faq",
    // },
    // {
    //   label: "Terms",
    //   url: "/terms",
    // },
  ];
}
