
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {}
