
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/about/background.svg";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  img = img;

  @Prop({ default: true })
  putOnMax!: boolean;
}
