<template>
  <div class="App-Top-Nav-Small">
    <el-affix class="hidden-lg-and-up" :offset="0">
      <div class="App-M-Top-Nav-Sm-H">
        <app-logo style="line-height: 50px" :size="50"></app-logo>
        <div>
          <el-button
            icon="el-icon-menu"
            @click="isOpen = true"
            is-all-borderless
            plain
          >
            Menu
          </el-button>
        </div>
      </div>
    </el-affix>
    <!--  -->
    <el-drawer
      v-model="isOpen"
      :withHeader="false"
      direction="ltr"
      size="300px"
    >
      <div>
        <el-space
          class="App-Top-Nav-Small-Spacer"
          alignment="space-between"
          direction="vertical"
          :size="12"
        >
          <!--  -->
          <div class="App-M-Top-Nav-Logo">
            <app-logo :size="60"></app-logo>
          </div>
          <!--  -->
          <div class="App-M-Top-Nav-Menu">
            <el-space direction="vertical" :size="16">
              <span v-for="link of links" :key="`menu-link${link.url}`">
                <router-link
                  v-slot="{ isExactActive }"
                  class="App-M-Top-Nav-Link"
                  :to="link.url"
                >
                  <el-link
                    class="Nav-Link"
                    :class="isExactActive ? 'active' : 'inactive'"
                    :type="isExactActive ? 'primary' : 'default'"
                    >{{ link.label }}</el-link
                  >
                </router-link>
              </span>
            </el-space>
          </div>
          <!--  -->
          <div class="App-M-Top-Nav-R">
            <el-space direction="vertical" :size="12">
              <el-button
                icon="el-icon-right"
                v-get-started
                v-if="showGetStarted"
                type="primary"
                size="large"
              >
                Get Started
              </el-button>
              <el-button
                icon="el-icon-user"
                @click="navigateToSignIn"
                is-all-borderless
                plain
                type="primary"
                size="large"
              >
                Sign In
              </el-button>
            </el-space>
          </div>
          <!--  -->
        </el-space>
      </div>
    </el-drawer>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  isOpen = false;

  @Prop({})
  links!: Array<any>;

  @Prop({})
  authLink!: string;

  navigateToSignIn() {
    window.open(this.authLink);
  }

  handleClose() {
    console;
  }
}
</script>

<style lang="scss">
.App-Top-Nav-Small {
  height: 100vh;
  .App-Top-Nav-Small-Spacer {
    height: 100vh;
    padding: 40px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > * {
      padding: 10px 20px;
    }
  }
  .el-link.is-underline:hover:after {
    border-width: 3px;
  }
  .Nav-Link {
    font-size: 1rem;
    font-weight: bold;
    line-height: 30px;
    border-bottom: 3px solid transparent;

    &.active::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 0;
      bottom: 0;
      border-bottom: 3px solid var(--el-link-default-active-color);
      transition: 300ms ease;
    }
  }
  // Holder
  .App-M-Top-Nav-Sm-H {
    width: 100%;
    line-height: map.get($--header, "height");
    background-color: rgba($color: white, $alpha: 0.95);
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: map.get($--box-shadow, "light");
    .App-Logo {
      display: flex;
      align-items: center;
    }
    .el-button {
      font-size: 10px;
      display: flex;
      align-items: center;
      .el-icon-menu {
        font-size: 30px;
      }
    }
  }
}
</style>
