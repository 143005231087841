<template>
  <div>
    <div class="app-contact-form" v-loading="loading">
      <!--  -->
      <!--  -->
      <div class="app-contact-form-title">
        <h2 txt-center>Contact Us <br /></h2>
        <p txt-center>
          Have a Query? We love assisting you. Get in touch with us and we will
          get back to you shortly.
        </p>
      </div>

      <!--  -->
      <!-- contact form -->
      <div>
        <el-form
          v-if="keyIsValid !== false"
          v-loading="loading"
          :rules="rules"
          @submit.prevent
          class="App-Styled-Form"
          :label-position="'top'"
          label-width="100px"
          :model="form"
          style="margin-top: 12px"
        >
          <!-- Names -->
          <!--  -->
          <el-form-item prop="names" label="names">
            <template v-slot:label>
              <el-icon>
                <User />
              </el-icon>
              Names
            </template>
            <el-input placeholder="John Doe" v-model="form.names"></el-input>
          </el-form-item>
          <!--  -->
          <!-- Names -->
          <!--  -->
          <el-form-item prop="email" label="Email">
            <template v-slot:label>
              <el-icon>
                <MessageBox />
              </el-icon>
              Email Address
            </template>
            <el-input
              placeholder="johndoe@e4t.com"
              v-model="form.email"
            ></el-input>
          </el-form-item>
          <!--  -->
          <!-- Names -->
          <!--  -->
          <el-form-item prop="phone" label="Phone">
            <template v-slot:label>
              <el-icon>
                <Phone />
              </el-icon>
              Contact Telephone (With code)
            </template>
            <el-input
              placeholder="+250780010733"
              v-model="form.phone"
            ></el-input>
          </el-form-item>
          <!-- message -->
          <el-form-item
            v-if="form.key === undefined"
            prop="message"
            label="Message"
          >
            <template v-slot:label>
              <el-icon>
                <Message />
              </el-icon>
              message
            </template>
            <el-input
              type="textarea"
              placeholder="Message/question/comment"
              v-model="form.message"
            />
          </el-form-item>
          <!--  -->
          <!--  -->
          <div txt-center>
            <el-button
              :disabled="!form.message || !form.names || !form.email"
              is-block
              size="large"
              type="primary"
              icon="el-icon-position"
              @click="submit"
            >
              Submit
            </el-button>
          </div>
          <!--  -->
        </el-form>
        <!--  -->
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ErrorUtil } from "@/shared";
import { Options, Vue } from "vue-class-component";
import {
  UserFilled,
  MessageBox,
  Message,
  User,
  Phone,
  Location,
} from "@element-plus/icons";

@Options({
  components: { Location, UserFilled, MessageBox, Message, User, Phone },
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  loading = false;
  title = "Contact E4T Team";
  form = {
    names: undefined,
    //   email: undefined,
    email: undefined,
    message: undefined,
  };
  /**
   * @sends the signup request to the API
   */
  submit(): void {
    this.loading = true;
    this.$api.main
      .post<any>("/v1/enquiries", this.form)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Successfully sent the enquiry",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err, {
            message: "Could not send the enquiry",
          }),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  resetForm() {
    this.form = {
      names: undefined,
      email: undefined,
      message: undefined,
    };
  }
}
</script>

<style lang="scss">
.app-contact-form {
  max-width: 500px;
  padding-top: 3vh;
  margin: 3vh auto 5vh auto;
  .app-contact-form-title {
    max-width: 500px;
    margin: 3vh auto 20px auto;
    h2 {
      margin: 0.4rem auto;
      font-weight: bolder;
    }
    p {
      font-size: 120%;
    }
  }
  .el-textarea__inner {
    min-height: 280px !important;
  }
}
</style>
