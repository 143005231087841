
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  @Prop({})
  name!: string;

  @Prop({})
  web!: string;
}
