
import img from "@/assets/illustrations/about/talent.svg";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;
}
