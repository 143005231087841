
import { Options, Vue } from "vue-class-component";
import TermsNavComponent from "./terms-nav.vue";
import { ElCollapseTransition } from "element-plus";

@Options({
  components: {
    TermsNavComponent,
    [ElCollapseTransition.name]: ElCollapseTransition,
  },
})
export default class extends Vue {}
