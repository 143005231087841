
import { Options, Vue } from "vue-class-component";
import AppHomeTop from "./home-top.vue";

@Options({
  components: {
    AppHomeTop,
  },
})
export default class extends Vue {}
