
import { Options, Vue } from "vue-class-component";
import { CircleCheck } from "@element-plus/icons";

@Options({
  components: { CircleCheck },
})
export default class extends Vue {
  founderSteps = [
    { description: "Go to the E4T marketplace" },
    { description: "Register and complete the talent profile you need" },
    {
      description:
        "See talent candidates tailored to your specific opportunity",
    },
    { description: "Find talent candidates with Boost" },
  ];

  talentSteps = [
    {
      description:
        "Register upon being invited and complete a talent assessment",
    },
    {
      description:
        "See entrepreneurial opportunities that match your skills, purpose and desired rewards",
    },
    {
      description:
        "Find your Boost founding team and get early access to opportunities",
    },
  ];
}
