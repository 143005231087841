
import { Options, Vue } from "vue-class-component";
import AboutTop from "./about-top.vue";

@Options({
  components: {
    AboutTop,
  },
})
export default class extends Vue {}
