<template>
  <div>
    <div class="App-Terms-Sub-Header">
      <h1 txt-center m-t-0>Glossary</h1>
      <div>
        <h2 txt-center>Dimensions of Entrepreneurial Ability</h2>
        <p txt-center>
          This document describes the dimensions of entrepreneurial ability
          measured in the E4T Entrepreneurial Profile Survey. These dimensions
          have been determined based on academic research to be the most salient
          personal characteristics affecting entrepreneurial outcomes.
        </p>
      </div>
      <el-divider></el-divider>
    </div>
    <div>
      <el-space direction="vertical" :size="12">
        <div v-for="item of items" :key="item.heading">
          <h3 f-w-700>{{ item.heading }}</h3>
          <p>
            {{ item.description }}
          </p>
        </div>
      </el-space>
    </div>
  </div>
</template>

<script lang="ts">
import { glossary } from "@/shared";
import { Options, Vue } from "vue-class-component";

@Options({
  title: "E4T: Glossary",
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  items = glossary;
}
</script>

<style lang="scss"></style>
