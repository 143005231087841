<template>
  <div class="App-Terms-Nav">
    <div>
      <el-space alignment="flex-start" direction="vertical" :size="6">
        <router-link
          v-slot="{ isExactActive }"
          :to="link.url"
          v-for="link of links"
          :key="link.url"
        >
          <el-button
            type="text"
            class="App-Terms-Nav-M"
            :class="isExactActive ? 'Active' : 'Inactive'"
            icon="el-icon-circle-check"
          >
            <div class="App-Terms-Nav-Desc">
              {{ link.label }}
            </div>
          </el-button>
        </router-link>
      </el-space>
      <!--  -->
      <div m-t-3>
        <div txt-center>
          <span mute-secondary>Other Links</span>
        </div>
      </div>
      <!--  -->
      <el-space alignment="flex-start" direction="vertical" :size="6">
        <router-link
          v-slot="{ isExactActive }"
          :to="link.url"
          v-for="link of otherLinks"
          :key="link.url"
        >
          <el-button
            :class="isExactActive ? 'Active' : 'Inactive'"
            type="text"
            class="App-Terms-Nav-M"
            icon="el-icon-circle-check"
          >
            <div class="App-Terms-Nav-Desc">{{ link.label }}</div>
          </el-button>
        </router-link>
      </el-space>
      <!--  -->
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { CircleCheck } from "@element-plus/icons";

@Options({
  components: { CircleCheck },
})
export default class extends Vue {
  links = [
    {
      label: "Terms of Service",
      url: "/terms/terms-of-service",
    },
    {
      label: "Terms of Use",
      url: "/terms/terms-of-use",
    },
    {
      label: "Privacy Policy",
      url: "/terms/privacy-policy",
    },
    {
      label: "Cookie Policy",
      url: "/terms/cookie-policy",
    },
  ];

  otherLinks = [
    {
      label: "Glossary",
      url: "/terms/glossary",
    },
  ];
}
</script>

<style lang="scss">
.App-Terms-Nav {
  padding: 10px 20px;
  .router-link-exact-active {
    .el-button {
    }
  }
}
.App-Terms-Nav-M {
  padding: 10px 4px;
  display: flex;
  align-items: flex-start;
  &.Inactive {
    color: black !important ;
  }
  margin-bottom: 4px;
  .el-icon {
    font-size: 20px;
    font-weight: bolder;
  }
  .App-Terms-Nav-Desc {
    padding: 0 4px;
  }
}
</style>
