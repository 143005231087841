<template>
  <div class="App-Page-H App-HomeTop">
    <br />
    <br />
    <h1
      id="e2156efa-5e63-450b-8859-8d73bdc758fa"
      class=""
      style="margin: 10vh 5px 5vh 0; max-width: 700px; font-size: 2.6rem"
    >
      E4T is the enabler that startup founders need to swiftly get to product
      market fit and beyond, to scale and growth.
      <!-- <mark class="highlight-orange"> [home page]</mark> -->
    </h1>
    <!-- <hr id="ba432726-7e3b-41ee-84cc-c9a5b28ccad3" /> -->
    <p id="d9006598-5416-48fc-abc1-daf448470d64" class="">
      <el-button icon="el-icon-right" v-get-started size="large" type="primary">
        Get Started
      </el-button>
    </p>
    <br />
    <hr />
    <br />
    <h3 id="4df41f92-d6cc-40a1-a8e9-f575fa113737" class="">
      Unlike founder-talent matching platforms before it, E4T calculates the new
      unit of account for successful early-phase startup teams. We call it
      entrepreneurial boost.
    </h3>
    <p id="a1113e96-090e-4fc7-863c-969ea0e15a92" class="">
      <strong>
        With this metric as its basis, the E4T marketplace enables early-phase
        startup founders to find talent that create entrepreneurial boost for
        their specific teams, and for the startup challenge at hand.
      </strong>
    </p>
    <h3 id="45c463a9-c385-40d4-8b29-56f31d10abc8" class="">
      <strong
        >For startup founders and the entrepreneurial talent on their teams,
        success looks like two things</strong
      >:
      <!-- <mark class="highlight-orange">[Home page, after main CTA]</mark> -->
    </h3>
    <figure
      class="block-color-gray_background callout"
      style="white-space: pre-wrap; display: flex"
      id="a7042f1c-38f4-492e-a710-dbce87ee335e"
    >
      <div style="font-size: 1.5em"><span class="icon">🥇</span></div>
      <div style="width: 100%; line-height: 3em">
        Getting to product-market fit (PMF)
      </div>
    </figure>
    <figure
      class="block-color-gray_background callout"
      style="white-space: pre-wrap; display: flex"
      id="e2644980-0ea0-4b45-936e-10ca498b5163"
    >
      <div style="font-size: 1.5em"><span class="icon">🥈</span></div>
      <div style="width: 100%; line-height: 3em">Getting to growth — fast.</div>
    </figure>
    <h3 id="c5ce54ca-bb8b-47d1-a9f7-f5b7f9c4a016" class="">
      <strong>
        Hidden within these though is the principal challenge: getting the right
        entrepreneurial people on board
      </strong>
      <mark class="highlight-orange">
        <!-- <strong>[Home page, after main CTA]</strong> -->
      </mark>
    </h3>
    <p id="97f7567e-91c9-404b-9b4e-9f4f5c6a0261" class="">
      The right entrepreneurial people allow you to execute with maximum
      effectiveness and speed; people who don&#x27;t just add domain expertise,
      but who are force multipliers— elevating the entire team&#x27;s collective
      entrepreneurial ability — people with &#x27;entrepreneurial boost&#x27;.
    </p>
    <blockquote id="7eefb8c7-0381-49a7-b268-5d167c40cce7" class="">
      &quot;Ideas are a dime a dozen, it really comes down to
      <em>the people </em>(italics ours), and I just cannot tell you how true
      that is”
    </blockquote>
    <p id="0a234ead-7a9b-44e5-a599-0cc6694eeb16" class="">
      — Tanya Baker, Managing Director &amp; Head of Goldman Sachs Accelerate
      (the firm’s internal innovation engine that identifies, invests in, and
      incubates new businesses for Goldman Sachs)
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/about/talent.svg";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;
}
</script>

<style lang="scss"></style>
