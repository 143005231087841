<template>
  <div class="App-FounderTalent-Top">
    <h1
      style="margin: 10vh 5px 5vh 0; max-width: 700px; font-size: 2.6rem"
      id="3c80e833-b7f5-4515-a0b5-f30c83f8fef3"
      class=""
    >
      E4T — the next frontier of early phase value creation...
      <!-- <mark class="highlight-orange">
        [Use this as heading for Founders and Talent page]
      </mark> -->
    </h1>
    <p id="ecd64fda-50ad-43ca-a2be-42ad0caa9b63" class=""></p>
    <div id="95793453-e92b-4cd5-a5f8-5b5d0cf62744" class="column-list">
      <div
        id="a4021cca-7b08-426f-8fd5-a7515d752dca"
        style="width: 50%"
        class="column"
      >
        <h2 id="0da5c2e7-5b2c-4d39-bd29-a5fc2e4ee31c" class="">
          Where founders find talent who boost the game of their entire team
        </h2>
      </div>
      <div
        id="bdbef11b-b509-4794-bd71-666655e777ca"
        style="width: 50%"
        class="column"
      >
        <h2 id="d7aef001-de56-494c-bcaa-436957b3c5ab" class="">
          Where talent find high-value opportunities that match their
          entrepreneurial skill
        </h2>
      </div>
    </div>
    <h3 id="ac9bf0b9-0e15-4a36-9636-cc5254f7be72" class="">
      <strong
        >According to conventional wisdom, figuring out which entrepreneurial
        talents to onboard or partner is more a &quot;dark art&quot;, than
        reliable science. No surprise then that relying on these haphazard
        methods, produces outcomes no better than chance. It needn&#x27;t be so.
        The proprietary algorithms that power the E4T marketplace, can swiftly
        determine what matters most to each party:
      </strong>
      <!-- <mark class="highlight-orange">
        <strong>[Founders and Talent page to follow ]</strong>
      </mark> -->
    </h3>
    <div id="05f6be90-fe0c-4748-812f-8dbf0fe9adb3" class="column-list">
      <div
        id="a653917b-03c9-40a9-8d4c-ac0a9de9e3a3"
        style="width: 50%"
        class="column"
      >
        <figure
          class="block-color-gray_background callout"
          style="white-space: pre-wrap; display: flex"
          id="3963a139-9831-4417-8a6b-f878e115f425"
        >
          <div style="font-size: 1.5em"><span class="icon">🚀</span></div>
          <div style="width: 100%">For Founders, E4T identifies</div>
        </figure>
        <p id="54cfc490-658c-4832-a79b-2c250bd2f469" class="">
          Talent who will be the best early-phase partners:
        </p>
        <ul id="37975bfb-14b9-469c-9138-869a5afbaefd" class="bulleted-list">
          <li style="list-style-type: disc">
            Those with the most apt domain expertise
          </li>
        </ul>
        <ul id="ee0d426c-8e7e-4413-9018-07728bd14210" class="bulleted-list">
          <li style="list-style-type: disc">
            Those who provide the most entrepreneurial boost for a given
            opportunity and team.
          </li>
        </ul>
      </div>
      <div
        id="d6db617e-d734-4618-abf1-d47a47162280"
        style="width: 50%"
        class="column"
      >
        <figure
          class="block-color-gray_background callout"
          style="white-space: pre-wrap; display: flex"
          id="4bd34b80-187a-448b-a815-e70b27d78012"
        >
          <div style="font-size: 1.5em"><span class="icon">🧠</span></div>
          <div style="width: 100%">For Talent, E4T identifies</div>
        </figure>
        <p id="1e04f3c8-5802-46f9-a990-bea1db32aa69" class="">
          High-potential opportunities where...
        </p>
        <ul id="a35d68dc-a043-4d01-bffb-9142676f55d4" class="bulleted-list">
          <li style="list-style-type: disc">
            The talent&#x27;s domain expertise is an absolute necessity.
          </li>
        </ul>
        <ul id="d902295f-5986-4d18-8eb7-15c167c172fc" class="bulleted-list">
          <li style="list-style-type: disc">
            The talent&#x27;s entrepreneurial competence boosts the whole
            team&#x27;s.
          </li>
        </ul>
        <p id="b5ba0df6-b94c-4e19-9f44-3d964f368920" class=""></p>
      </div>
    </div>
    <p id="9a35d009-cce6-463e-ae98-adddbcec9fd4" class="">
      As well as identifying and connecting founders and the early-phase talents
      (that propel startups to <em>and</em> through Product Market Fit), E4T
      naturally aligns the incentives of all parties, whilst altering the
      balance of risk and reward in everybody&#x27;s favour.
    </p>
    <!--  -->
    <h1 id="1ff01445-a642-4434-bfd9-2ccd9553fe75" class="">
      How to win the startup race...
      <!-- <mark class="highlight-orange">
        [Use as heading for Founders and Talent page and the copy below
        immediately]
      </mark> -->
    </h1>
    <p id="4adb40e7-5500-4a10-aaec-57aca3f6c944" class=""></p>
    <h3 id="7eef5f4c-cc9b-4b38-ae67-4fe56ec08699" class="">
      Victory in the startup race means swiftly getting to Product-Market Fit
      (PMF), and growing faster than your competitors. For that you need a
      startup team with entrepreneurial boost for each phase of the startup
      build process.
    </h3>
    <p id="10e8978e-990a-45bd-966e-03d134199d61" class="">
      Like a rocket in a &quot;space race&quot;, every startup, and every
      entrepreneurial individual is trying to fight the “gravitational pull” of
      failure, and aiming for breakthrough to “stratospheric” success. For
      startups, it requires nothing less than “rocket boosters” to reach the
      escape velocity of market-defining scale and growth. Entrepreneurial boost
      at each phase of the startup build process is what creates and sustains
      this momentum.
    </p>
    <p id="af117ee3-12ff-457e-943b-15abfa8f251a" class=""></p>
    <p id="77e22c01-0df7-421d-857c-967072ec2f41" class="">
      Achieving this boost, takes more than domain expertise. It also needs to
      be fueled by the combined entrepreneurial mix of the team. E4T facilitates
      exactly this kind of extreme propulsion.
    </p>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/about/founder.svg";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;
}
</script>

<style lang="scss">
@import "../variables";
</style>
