<template>
  <div class="App-BlockQuote">
    <figure>
      <blockquote :cite="web">
        <p txt-center>
          <slot name="content" />
        </p>
      </blockquote>
      <figcaption txt-center>
        — {{ name }},
        <cite>
          <slot name="cite" />
        </cite>
      </figcaption>
    </figure>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  @Prop({})
  name!: string;

  @Prop({})
  web!: string;
}
</script>

<style lang="scss">
.App-BlockQuote {
  text-align: center;
  figure {
    max-width: 900px;
    margin: auto;
    p {
      font-size: 160%;
      color: black;
      font-weight: 600;
    }
    figcaption {
      font-size: 120%;
    }
  }
}
</style>
