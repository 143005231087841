<template>
  <div>
    <!--  -->
    <app-cookie-notice />
    <!--  -->
    <router-view v-slot="{ Component }">
      <el-collapse-transition>
        <component :is="Component" />
      </el-collapse-transition>
    </router-view>
  </div>
</template>

<script>
// collapse
import { ElCollapseTransition } from "element-plus";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    [ElCollapseTransition.name]: ElCollapseTransition,
  },
})
export default class extends Vue {}
</script>

<style lang="scss">
@import "./styles.scss";
</style>
