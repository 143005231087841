
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  isOpen = false;

  @Prop({})
  links!: Array<any>;

  @Prop({})
  authLink!: string;

  navigateToSignIn() {
    window.open(this.authLink);
  }

  handleClose() {
    console;
  }
}
