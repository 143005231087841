<template>
  <div>
    <el-affix class="hidden-md-and-down" :offset="0">
      <div class="App-M-Top-Nav">
        <!--  -->
        <div class="App-M-Top-Nav-L">
          <el-space :size="12">
            <div class="App-M-Top-Nav-Logo">
              <app-logo :size="60"></app-logo>
            </div>
            <div class="App-M-Top-Nav-Menu">
              <el-space :size="16">
                <span v-for="link of links" :key="`menu-link${link.url}`">
                  <router-link
                    v-slot="{ isExactActive }"
                    class="App-M-Top-Nav-Link"
                    :to="link.url"
                  >
                    <el-link
                      class="Nav-Link"
                      :class="isExactActive ? 'active' : 'inactive'"
                      :type="isExactActive ? 'primary' : 'default'"
                      >{{ link.label }}</el-link
                    >
                  </router-link>
                </span>
              </el-space>
            </div>
          </el-space>
        </div>
        <!--  -->
        <!--  -->
        <div class="App-M-Top-Nav-R">
          <el-space :size="12">
            <el-button
              icon="el-icon-right"
              v-get-started
              v-if="showGetStarted"
              type="primary"
            >
              Get Started
            </el-button>
            <el-button
              icon="el-icon-user"
              @click="navigateToSignIn"
              is-all-borderless
              plain
              type="primary"
            >
              Sign In
            </el-button>
          </el-space>
        </div>
        <!--  -->
      </div>
    </el-affix>
    <!--  -->
    <!--  -->
    <TopNavSmall
      :showGetStarted="showGetStarted"
      :authLink="authLink"
      :links="links"
    />
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TopNavSmall from "./top-nav-small.vue";

@Options({
  components: {
    TopNavSmall,
  },
})
export default class extends Vue {
  @Prop({ type: Boolean, default: true })
  showGetStarted!: boolean;

  authLink = `${process.env.VUE_APP_BASE_URL_AUTH}/login`;

  navigateToSignIn() {
    window.open(this.authLink);
  }
  links = [
    {
      label: "Founder & Talent",
      url: "/founder-talent",
    },
    {
      label: "Why E4T",
      url: "/why-e4t",
    },
    {
      label: "About Us",
      url: "/about",
    },
    // {
    //   label: "FAQ",
    //   url: "/faq",
    // },
    // {
    //   label: "Terms",
    //   url: "/terms",
    // },
  ];
}
</script>

<style lang="scss">
.App-M-Top-Nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  line-height: map.get($--header, "height");
  transition: 300ms ease;
  background-color: rgba($color: white, $alpha: 0.95);
  //
  // Logo fixes
  .App-Logo .el-image__inner {
    width: unset !important;
  }
  //
  .App-M-Top-Nav-L {
    display: flex;
    align-items: center;
    .App-Logo {
      padding: 4px 4px 4px 2px;
      display: flex;
      line-height: 70px;
      align-self: center;
      // width: 97px;
      transition: 300ms ease-in-out;
    }
  }
  // Nav
  .App-M-Top-Nav-Menu {
    padding: map.get($--header, "padding");
    transition: 300ms ease;
    * {
      transition: 300ms ease;
    }
  }
  .App-M-Top-Nav-Link {
    display: inline-block;
    min-width: 90px;
    transition: 300ms ease;
    text-align: center;
    padding: 4px 20px;
    transition: 300ms ease;
    .el-link {
      transition: 300ms ease;
      border-width: 3px;
    }
    * {
      font-weight: 670;
    }
    .Nav-Link.active {
      border-bottom: 2px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        bottom: 0;
        border-bottom: 3px solid var(--el-link-default-active-color);
        transition: 300ms ease;
      }
    }
  }
}
</style>
