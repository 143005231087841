
import { ErrorUtil } from "@/shared";
import { Options, Vue } from "vue-class-component";
import {
  UserFilled,
  MessageBox,
  Message,
  User,
  Phone,
  Location,
} from "@element-plus/icons";

@Options({
  components: { Location, UserFilled, MessageBox, Message, User, Phone },
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  loading = false;
  title = "Contact E4T Team";
  form = {
    names: undefined,
    //   email: undefined,
    email: undefined,
    message: undefined,
  };
  /**
   * @sends the signup request to the API
   */
  submit(): void {
    this.loading = true;
    this.$api.main
      .post<any>("/v1/enquiries", this.form)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Successfully sent the enquiry",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err, {
            message: "Could not send the enquiry",
          }),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  resetForm() {
    this.form = {
      names: undefined,
      email: undefined,
      message: undefined,
    };
  }
}
