<template>
  <div class="App-M-Footer">
    <!--  -->
    <!-- Banner Holder -->
    <div v-if="showBanner" class="App-M-Footer-B-H">
      <FooterBanner />
    </div>
    <!-- Main Holder -->
    <div class="App-M-Footer-M-H">
      <div txt-center>
        <el-space direction="vertical" :size="12">
          <div>
            <app-logo :size="40" />
          </div>
          <div>
            <div class="App-M-Footer-Menu">
              <el-space
                style="justify-content: center"
                alignment="center"
                wrap
                :size="16"
              >
                <span v-for="link of links" :key="`menu-link${link.url}`">
                  <router-link class="App-M-Footer-Link" :to="link.url">
                    <el-link>{{ link.label }}</el-link>
                  </router-link>
                </span>
              </el-space>
            </div>
          </div>
        </el-space>
      </div>
      <el-divider />
      <div>
        <app-footer :openExternal="false" size="large" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FooterBanner from "./footer-banner.vue";

@Options({
  components: { FooterBanner },
})
export default class extends Vue {
  @Prop({ default: true })
  showBanner!: boolean;
  links = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Founder & Talent",
      url: "/founder-talent",
    },
    {
      label: "Why E4T",
      url: "/why-e4t",
    },
    {
      label: "About Us",
      url: "/about",
    },
    {
      label: "Contact Us",
      url: "/contact",
    },
    // {
    //   label: "FAQ",
    //   url: "/faq",
    // },
  ];
}
</script>

<style lang="scss">
.App-M-Footer {
  transition: 300ms ease;
  padding: 20px 0 40px 0;
  // Nav
  .App-M-Footer-Menu {
    padding: map.get($--header, "padding");
    transition: 300ms ease;
    * {
      transition: 300ms ease;
    }
  }
  .App-M-Footer-Link {
    display: inline-block;
    min-width: 90px;
    transition: 300ms ease;
    text-align: center;
    padding: 4px 20px;
    .el-link {
      transition: 300ms ease;
    }
    * {
      font-weight: 600;
    }
  }
}
</style>
