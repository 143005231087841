<template>
  <div class="App-AboutTop">
    <h1
      style="margin: 10vh 5px 5vh 0; max-width: 700px; font-size: 2.6rem"
      id="dbfc7c5a-5049-4a83-ba90-5b80f002749a"
      class=""
    >
      <strong
        >How do founders usually put together an early-phase startup team with
        the best chance of success?</strong
      >
      <!-- <mark class="highlight-orange"><strong> [About Us page]</strong></mark> -->
    </h1>
    <div id="0e605159-fd5b-4335-8e8b-fc5548895d1b" class="column-list">
      <div
        id="d1b439cb-8484-4e7f-90c2-7ccd208dc5b3"
        style="width: 50%"
        class="column"
      >
        <figure
          class="block-color-gray_background callout"
          style="white-space: pre-wrap; display: flex"
          id="e97af8cd-94f1-4583-82d6-d30f8381a308"
        >
          <div style="font-size: 1.5em"><span class="icon">1️⃣</span></div>
          <div style="width: 100%">
            Domain-fit: does this individual have the requisite skill and domain
            expertise for a given part of the startup build phase?
          </div>
        </figure>
      </div>
      <div
        id="490ca4de-20c1-4be1-99cc-eb4279b9c3ed"
        style="width: 50%"
        class="column"
      >
        <figure
          class="block-color-gray_background callout"
          style="white-space: pre-wrap; display: flex"
          id="6673e10d-6a8a-4740-80a7-d85079e8082b"
        >
          <div style="font-size: 1.5em"><span class="icon">2️⃣</span></div>
          <div style="width: 100%">
            Entrepreneurial-fit: does this individual raise the collective
            entrepreneurial skills of my particular existing team, for the
            startup challenge at hand?
          </div>
        </figure>
      </div>
    </div>
    <h3 id="617dd746-4539-4940-88f3-c83bcca6401f" class="">
      It may seem crude, but here&#x27;s how these crucial questions (of
      domain-fit and entrepreneurial-fit) are currently decided...
    </h3>
    <div id="647b2ad7-3d27-45f7-94a8-d912a957f594" class="column-list">
      <div
        id="bb687d79-82e6-4fd6-9866-f770bd35a184"
        style="width: 50%"
        class="column"
      >
        <p id="fcbac4d5-e439-4ca9-859b-65e3915a6825" class="">🤔Gut feeling</p>
      </div>
      <div
        id="8c4eb7b3-7a3b-4cac-a771-8b7d5571842e"
        style="width: 50%"
        class="column"
      >
        <p id="e2987cbe-3e4e-401f-9831-bb83041d2911" class="">
          😇 Performance on other teams/opportunities
        </p>
      </div>
    </div>
    <div id="d0fc6275-7bb2-43fc-88d1-fa76c17e653e" class="column-list">
      <div
        id="7de4ce0d-bdbe-4335-902c-8229ba147bc8"
        style="width: 50%"
        class="column"
      >
        <p id="da909460-8404-48a9-9c58-fa8a67fca92d" class="">
          🤝Connectedness of the candidate
        </p>
      </div>
      <div
        id="eef9c616-8d04-474f-b120-8493b6c5d60b"
        style="width: 50%"
        class="column"
      >
        <p id="a3b6f039-7dcb-4df5-a865-97facde1e95d" class="">
          🎓Academic credentials
        </p>
      </div>
    </div>
    <div id="2f4878f6-48bf-4fdb-9bad-3d5ca66e9c48" class="column-list">
      <div
        id="49380795-359b-44b2-8234-bae15103d2fb"
        style="width: 50%"
        class="column"
      >
        <p id="6fcd2f89-0ab4-4191-b87e-6078e7e376f6" class="">
          🎭 Interview performance
        </p>
      </div>
      <div
        id="d056e34c-c7c7-440f-a505-682b6fbbfa6a"
        style="width: 50%"
        class="column"
      >
        <p id="84861941-00d9-43ed-9190-21a684d62d70" class="">🤞🏻Chance</p>
      </div>
    </div>
    <div id="2f4878f6-48bf-4fdb-9bad-3d5ca66e9c48" class="column-list">
      <div
        id="49380795-359b-44b2-8234-bae15103d2fb"
        style="width: 50%"
        class="column"
      >
        <p style="width: 100%">
          💡 Founder/talent matching based on skills and interest
        </p>
      </div>
    </div>
    <br>
    <blockquote id="6c30b687-c0d1-401a-8aa9-2f64b5925c21" class="">
      &quot;We do find that <em>certain traits work better</em> (italics ours).
      Having the ability to navigate white space, put structure where there is
      none, and inspire people is non-negotiable.”
    </blockquote>
    <p id="21fe9832-6f8b-4335-8aa3-f9ef6da8bda9" class="">
      — Tanya Baker, Managing Director &amp; Head of Goldman Sachs Accelerate
      (the firm’s internal innovation engine that identifies, invests in, and
      incubates new businesses for Goldman Sachs)
    </p>
    <h3 id="51a7c0e9-7b05-4e5a-b8cf-9beabb540fc3" class="">
      Our verdict? The conventional wisdom behind building startup teams has
      been reduced to a &quot;dark art&quot;, and yet the consequences are too
      big to ignore. Little wonder that 80% of startups fail to get to PMF.
      Clearly, startups and entrepreneurial individuals deserve better... a lot
      better
    </h3>
    <blockquote id="709bf990-41e3-4fef-ae92-1746c0fd1240" class="">
      “...in company building, <em>the team is the product</em>… and the best
      product will win. You can have the best strategy or market opportunity in
      the world, but getting the team wrong results in stunted growth at best;
      failure at worst. By contrast, getting it right is like being part of a
      SpaceX launch, and it can be the driving factor for a company to achieve
      lift-off.&quot;
    </blockquote>
    <p id="fbc55a05-8095-4329-afac-23a31234c06c" class="">
      —Pete Flint,
      <em
        >General Partner at NFX, a seed-stage venture firm based in San
        Francisco</em
      >
    </p>
    <p id="10c5000b-019e-4525-a5bf-15a3f646a2ed" class=""></p>
    <h3 id="3e3eaf1e-ef47-40c2-9b1a-41be7eb3c961" class="">
      <strong
        >At E4T we&#x27;ve seen firsthand how success comes from working with
        highly skilled entrepreneurial talent who are force multipliers. They
        contribute to the right mix of domain expertise, but crucially, they
        &#x27;boost&#x27; the collective entrepreneurial breadth of your entire
        team. This boost unlocks the team&#x27;s potential and elevates
        performance to levels not otherwise possible.</strong
      >
    </h3>
    <p id="d3414373-e92f-426d-b053-188b6968d597" class=""></p>
    <div id="08b4d808-cbe5-409e-a2cf-4787728c44eb" class="column-list">
      <div
        id="68913b77-520c-4ab5-85fc-324a0f76148f"
        style="width: 50%"
        class="column"
      >
        <figure
          class="block-color-gray_background callout"
          style="white-space: pre-wrap; display: flex"
          id="eabec21b-d484-4c39-a977-8a66a80fb5a4"
        >
          <div style="font-size: 1.5em"><span class="icon">💡</span></div>
          <div style="width: 100%">
            Think of entrepreneurial boost as the extent to which a new
            early-phase team member can elevate the entrepreneurial breadth (mix
            of entrepreneurial skills) of the existing startup team, for the
            startup challenge at hand.
          </div>
        </figure>
      </div>
      <div
        id="c4e3c318-0998-49a6-8a7b-18c1cb8573c9"
        style="width: 50%"
        class="column"
      >
        <figure
          class="block-color-gray_background callout"
          style="white-space: pre-wrap; display: flex"
          id="3d08ba61-a99e-47b4-9bf0-060d46355b4c"
        >
          <div style="font-size: 1.5em"><span class="icon">🚀</span></div>
          <div style="width: 100%">
            Boost occurs when the entrepreneurial competence of a talent joining
            a startup team, elevates the collective entrepreneurial breadth of the
            team in ways that create complimentary advantage.
          </div>
        </figure>
      </div>
    </div>
    <p id="f6167782-da98-44cf-a7d3-22c9226690cd" class=""></p>
    <hr id="c527effe-6045-4854-9ce0-c8c70edac92f" />
    <p id="27fbb01b-7206-4506-ac22-b9843bf94003" class=""></p>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/about/talent.svg";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;
}
</script>

<style lang="scss"></style>
