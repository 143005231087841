<template>
  <div>
    <TermsFetcher :path="'cookie-policy'" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TermsFetcher from "../terms-fetcher.vue";

@Options({
  title: "E4T: Cookie Policy",
  components: {
    TermsFetcher,
  },
})
export default class extends Vue {}
</script>

<style lang="scss"></style>
