<template>
  <div class="App-WhyE4T-Top">
    <h1
      style="margin: 10vh 5px 5vh 0; max-width: 700px; font-size: 2.6rem"
      id="4fe6e826-a22d-46d9-b865-ddee6f9f4b88"
      class=""
    >
      Move fast and... create win-wins
      <!-- <mark class="highlight-orange">[Why E4T]</mark> -->
    </h1>
    <div id="b3a3bb17-c58d-4c47-a75c-5b60712f37d0" class="column-list">
      <div
        id="7cc05bc0-85fa-4421-905b-8aa814912838"
        style="width: 50%"
        class="column"
      >
        <h1
          id="34bcc909-9b7a-40d2-8a85-881655586688"
          class="block-color-red_background"
        >
          👇🏾E4T reduces key failure factors
        </h1>
        <h2 id="e5fd127a-994a-4d62-b7ff-7ffaefaee762" class="">
          De-risk early phase team formation
        </h2>
      </div>
      <div
        id="9036efa4-97ef-4258-9096-5c03ddcfecf8"
        style="width: 50%"
        class="column"
      >
        <h1
          id="aaaf2085-b3d1-42dd-8b9f-4e59fa2b0535"
          class="block-color-teal_background"
        >
          👆🏾 E4T increases key success factors
        </h1>
        <h2 id="9e908715-cef5-47ff-a1bb-bb321eb57ae2" class="">
          Achieve win-win outcomes for all early phase participants
        </h2>
      </div>
    </div>
    <div id="980d286f-e2cc-4a0b-b60d-3e2ac68411c8" class="column-list">
      <div
        id="63a4ab78-4f36-4c3b-bf9a-12ea2f4983b5"
        style="width: 50%"
        class="column"
      >
        <p id="c6b7cf3a-8991-4686-9d87-15a6ef3951c1" class="">
          The ability to ideate, execute, and pivot are all a function of your
          people and the boost they contribute. By screening out poor-fit,
          no-boost yielding candidates, E4T improves a startup success odds
          dramatically.
        </p>
      </div>
      <div
        id="d78b2c0d-8c69-432e-ae99-75bf772c1f24"
        style="width: 50%"
        class="column"
      >
        <p id="d3f67513-a418-478c-8d17-96230d262736" class="">
          Too often zero-sum thinking produces founders or teams that
          aren&#x27;t happy with their rewards. E4T&#x27;s design naturally
          aligns incentives for each party—increasing the likelihood of outsized
          win-win outcomes, and baking a bigger pie for everyone.
        </p>
      </div>
    </div>
    <p id="5e6b8c30-3b97-48a8-850c-3ff6ffe11703" class=""></p>
    <div id="894d2cba-9721-4e81-8480-629794560a8a" class="column-list">
      <div
        id="58e51ba9-59b3-4ff7-8d51-0fb42f5265ed"
        style="width: 50%"
        class="column"
      >
        <h2 id="22f66500-9eb3-4a7c-8ed1-d0bfb481f428" class="">
          Eliminate guess work and poor partnership decisions
        </h2>
      </div>
      <div
        id="d8948a43-99d8-417d-9e5b-904d85e8f11c"
        style="width: 50%"
        class="column"
      >
        <h2 id="68877cfb-f2a3-4795-8a6f-9303a90e660e" class="">
          Allocate resources more effectively
        </h2>
      </div>
    </div>
    <div id="79d954a0-9115-4089-966d-8f4878ee6c76" class="column-list">
      <div
        id="b7c656c9-f438-42fe-8699-04d5ab0c7bc2"
        style="width: 50%"
        class="column"
      >
        <p id="40f633da-ef76-4374-a3e4-a04178c46b4e" class="">
          By quantifying an entrepreneurial talent’s value to your specific
          opportunity or team, E4T eliminates the guesswork that so often
          saddles founders with early phase contributors that aren&#x27;t a good
          fit, who will need replacing - often at high opportunity cost, and
          lost time.
        </p>
        <h2 id="61696b7f-47ca-48e8-97b8-0a30ca0e68dc" class="">
          Save thousands of man hours each year
        </h2>
        <p id="316ca558-5d46-4d3a-b279-ce56c8b443a6" class="">
          By identifying either the right talent for an opportunity or, in the
          case of entrepreneurial individuals, the right opportunity for their
          talent, E4T improves hiring/partnering efficiency and effectiveness.
        </p>
        <p id="f1efdb23-462e-40fb-accd-6f8418afa76a" class=""></p>
        <h2 id="9aac2cc3-a0fd-4b26-956f-190978996da0" class="">
          The right talent at the right time
        </h2>
        <p id="9cf2a4b1-ce74-4273-a37f-73b80b53b4cd" class=""></p>
        <p id="4bfe769e-ae4a-440b-86ae-62a6cc88d8d7" class="">
          E4T improves the timeliness of your hires/partnerships, so that your
          hiring the right individual for the right opportunity into the right
          team at the right time.
        </p>
      </div>
      <div
        id="4ada3b5d-f8b4-4a65-956c-9091a199da0b"
        style="width: 50%"
        class="column"
      >
        <p id="a26d4680-ae70-4592-ad20-1a7035cbb939" class="">
          When you can quantify value more accurately, you can allocate
          resources more effectively. With E4T, founders avoid paying over the
          odds for talent, or under-rewarding the team that makes your startup
          possible. Similarly talent avoid wasting years in ill-fitting roles.
        </p>
        <p id="1bfc287f-175a-48d2-af44-f4e393395938" class=""></p>
        <h2 id="290c4497-6d7e-4e70-853e-20752609660f" class="">
          Move faster with a boosted team
        </h2>
        <p id="31ae7bd7-f51c-4f33-ae96-a8f15897d99d" class="">
          With the right team members on board, all unlocking each other&#x27;s
          entrepreneurial potential, your startup will move faster, strike the
          market with greater impact, and, if necessary, course correct sooner.
        </p>
      </div>
    </div>
    <!-- <p id="d4972192-3de5-4d50-bdba-989b7254d686" class="">[Get Started]</p> -->
    <div id="46e69977-659c-4063-bc86-080970182dba" class="column-list">
      <div
        id="0cf8c236-f637-4432-bedc-8cfde069866d"
        style="width: 50%"
        class="column"
      >
        <h3 id="80570d29-d2b4-460a-905c-5ce637549ffc" class="">
          <strong
            >Reducing risk, guess-work and time-wasted are great but coupled
            with increases in the size of the &#x27;pie&#x27; being baked (with
            win-win incentives), the effectiveness of your resource allocation,
            and a spike in operational speed - founders on the E4T marketplace
            put themselves on an unstoppable trajectory.</strong
          >
        </h3>
      </div>
      <div
        id="588f7157-90b8-4c41-aa66-3c62d04cad17"
        style="width: 50%"
        class="column"
      >
        <h3 id="a621689f-40af-4835-a76a-bf52e4b73567" class="">
          <strong
            >The power of E4Ts solution rests in its ability to cap or reduce
            downside risk for all, whilst maximising the upside for all. This
            cutting of bloat and streamlining of incentives produces a leaner,
            more incisive team, ready to make a dent in markets and
            history.</strong
          >
        </h3>
      </div>
    </div>
    <p id="d04c72d9-e291-4f6d-9631-27cc3c7e26a8" class=""></p>
    <!-- <p id="4ded1270-ecad-40ac-a26b-1b4f2d4e31a9" class="">[Get Started]</p> -->
    <h3 id="8dad057f-50e5-48f1-b603-590009abbdce" class="">
      <strong
        >Like no other solution before it, E4T aligns skilled entrepreneurial
        talent and founders as true partners playing a much larger game than the
        traditional zero-sum theatrics many have come to expect from early-phase
        team-building.</strong
      >
    </h3>
    <p id="ece4e190-c85c-4045-8091-036605852ced" class=""></p>
    <blockquote id="f6654380-66fe-43a6-8847-324d8b00bd74" class="">
      “I hired people because I thought they could do what I could-that was a
      mistake. I should have been hiring people who could do specifically what I
      couldn’t do.”
    </blockquote>
    <p id="91f8ec16-30ed-4e81-b0ce-2466593e3ac5" class="">
      —Peter Shankman, Founder of HARO and best-selling author.
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/about/founder.svg";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;
}
</script>

<style lang="scss">
@import "../variables";
.App-WhyE4T-Top {
}
</style>
