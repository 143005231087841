
import { Options, Vue } from "vue-class-component";
import TermsFetcher from "../terms-fetcher.vue";

@Options({
  title: "E4T: Cookie Policy",
  components: {
    TermsFetcher,
  },
})
export default class extends Vue {}
